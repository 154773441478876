<template>
  <!-- 頭部組件 -->

  <div class="web-heard">
    <div style="width: 50px;"></div>
    <img style="height: 60px;" src="../assets/img/logo-heard.png" alt="">
    <div class="nav-heard" style="width: 1200px;">
      <div :class="[activeIndex==1 ? 'activeClass' : 'errorClass']" @click="jump(1)">公司首頁</div>
      <div :class="[activeIndex==2 ? 'activeClass' : 'errorClass']" @click="jump(2)">关于我们</div>
      <div :class="[activeIndex==3 ? 'activeClass' : 'errorClass']"  @click="jump(3)">服务项目</div>
      <ul style="margin-top: 50px;" class="ul"  id="hoverdiv">
        <li :class="[activeIndex==4 ? 'activeClass' : '']" id="test1" @click="jump(4)">法律服务</li>
        <li :class="[activeIndex==5 ? 'activeClass' : '']" id="test2" @click="jump(5)">飞行员生活</li>
        <li :class="[activeIndex==6 ? 'activeClass' : '']" id="test3" @click="jump(6)">健康管理</li>
        <li :class="[activeIndex==7 ? 'activeClass' : '']" id="test4" @click="jump(7)">风险管理</li>
      </ul>
      <div :class="[activeIndex==8 ? 'activeClass' : 'errorClass']" @click="jump(8)">项目招标</div>
      <div :class="[activeIndex==9 ? 'activeClass' : 'errorClass']" @click="jump(9)">飞鹰联盟</div>
      <div :class="[activeIndex==10 ? 'activeClass' : 'errorClass']" @click="jump(10)">行业动态</div>
      <div :class="[activeIndex==11? 'activeClass' : 'errorClass']" @click="jump(11)">联系客服</div>
    </div>
    <div style="display: flex;align-items: center;">
      <img style="height: 24px;" src="../assets/img/phone.png" alt="">
      <img style="height: 24px;" src="../assets/img/number.png" alt="">
    </div>
    <div style="width: 50px;"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 1,
      show:true,
    };
  },
  methods: {
    jump(v) {
      this.activeIndex = v
      var t1 = document.getElementById('hoverdiv');
        t1.style.display = 'none';// 以块级样式显示
      if (v == 1) {
        this.$router.push('/')
      }
      if (v == 2) {
        this.$router.push('/About-us')
      }
      if (v == 3) {
        var t1 = document.getElementById('hoverdiv');
        t1.style.display = 'block';// 以块级样式显示
        this.$router.push('/Service-item')
      }
      if (v == 4) {
        var t1 = document.getElementById('hoverdiv');
        t1.style.display = 'block';// 以块级样式显示
        this.$router.push('/Legal-service')
      }
      if (v == 5) {
        var t1 = document.getElementById('hoverdiv');
        t1.style.display = 'block';// 以块级样式显示
        this.$router.push('/Pilot-life')
      }
      if (v == 6) {
        var t1 = document.getElementById('hoverdiv');
        t1.style.display = 'block';// 以块级样式显示
        this.$router.push('/Health-management')
      }
      if (v == 7) {
        var t1 = document.getElementById('hoverdiv');
        t1.style.display = 'block';// 以块级样式显示
        this.$router.push('/Risk-management')
      }
      if (v == 8) {
        this.$router.push('/Project-bidding')
      }
      if (v == 9) {
        this.$router.push('/Eagle-Alliance')
      }
      if (v == 10) {
        this.$router.push('/Industry-dynamics')
      }
      if (v == 11) {
        this.$router.push('/Contact-customer-service')
      }

    }
  }
}
</script>
<style lang="less">
.web-heard {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100px;
}
.nav-heard{
  display: flex;
  align-items: center;
  position: relative;
}
.ul{
  display: none;
  position: absolute;
  top:30px;
  left:374px;
  background-color: #fff;
  width: 100px;
}
.ul>li{
  // display: none;
   width: 100px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  background-color: #fff;
}
//  .ul>li:hover{
//   display: block;
//    width: 100px;
//   height: 80px;
//   line-height: 80px;
//   text-align: center;
//   background-color: red;
// }
.errorClass {
  // background-color: #275eab;
  width: 170px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

.activeClass {
  color: #275eab;
  border-bottom: 2px solid #275eab;
  width: 170px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

// nav:hover .active {
//   display: block !important;
//   // background-color: red;
//   position: absolute;
//   top: 60px;
//   left: 200px;
//   display: flex;
//   flex-direction: column;
//   width: 100px;
//   text-align: center;
// }
</style>
