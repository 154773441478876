import Vue from 'vue'
import App from './App.vue'
import router from './router'
// ui 样式
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
// 引入css样式
import "@/assets/css/ressest.css"
import '@/assets/css/global.css' /*引入公共样式*/
import drag from '@/utils/drag';
//在main.js引用drag.js的内容
// import './utils/drag'
  
import axios from 'axios'
axios.defaults.baseURL = 'https://jhht.jihongpilot.com';
// axios.defaults.baseURL = 'http://192.168.2.112:8081';
// baseUrl:"http://192.168.2.112:8081",
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// 引入vdeio 
import Video from 'video.js'
import 'video.js/dist/video-js.css' 
 
Vue.prototype.$http = axios

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  render: h => h(App)
}).$mount('#app')

